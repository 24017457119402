import React, { useCallback, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useWindowSize } from 'hooks';
import { bool, func, node, oneOf, string } from 'prop-types';
import { getScrollbarWidth, isSSR } from 'utils';
import disableScrollGetter from 'utils/disableScroll';

import { Text, Title } from '@s7/ui-kit';
import { CloseLinear } from '@s7/ui-kit/icons';

import cx from './ProfileModal.sss';

import { UrlStateService } from '../../services';
import { changeModal } from '../../store/modules/shared';

const TITLE_STYLES = { textAlign: 'center' };

export const ProfileModal = props => {
    const {
        actions,
        actionsLayout,
        children,
        isOpen,
        onClose,
        size,
        title,
        id,
        ...rest
    } = props;
    const { isDesktop } = useWindowSize();
    const dispatch = useDispatch();

    const handleOpen = useCallback(
        body => {
            body.style.display = 'block';
            body.style.marginRight = `${getScrollbarWidth()}px`;

            dispatch(changeModal({ isOpen: true }));
            UrlStateService.set(id, { onRemove: onClose });
        },
        [dispatch, id, onClose],
    );

    const handleClose = useCallback(
        body => {
            body.style.removeProperty('margin-right');
            dispatch(changeModal({ isOpen: false }));
            UrlStateService.remove(id);
        },
        [dispatch, id],
    );

    useEffect(() => {
        if (isSSR || isDesktop) {
            return undefined;
        }

        const { body } = document;

        if (isOpen) {
            handleOpen(body);
        } else {
            handleClose(body);
        }

        return () => {
            handleClose(body);
        };
    }, [handleClose, handleOpen, isDesktop, isOpen]);

    useEffect(() => {
        const disableScroll = disableScrollGetter({ maxWidth: 1280 });

        disableScroll(isOpen);

        return () => {
            disableScroll(false);
        };
    }, [isOpen]);

    return isDesktop ? (
        <ReactModal
            ariaHideApp={false}
            className={cx('container', { [`container-${size}`]: size })}
            isOpen={isOpen}
            overlayClassName={cx('overlay')}
            {...rest}
        >
            <div className={cx('root')}>
                {title && (
                    <div className={cx('title')}>
                        <Title level={3} style={TITLE_STYLES}>
                            {title}
                        </Title>
                    </div>
                )}

                {children}

                {actions && (
                    <div
                        className={cx('actions', {
                            [`actions-${actionsLayout}`]: actionsLayout,
                        })}
                    >
                        {actions}
                    </div>
                )}
            </div>

            {onClose && (
                <button className={cx('close')} type="button" onClick={onClose}>
                    <CloseLinear />
                </button>
            )}
        </ReactModal>
    ) : (
        <>
            <div className={cx('title', { hidden: !isOpen })}>
                {title && (
                    <span className={cx('title-text')}>
                        <Text weight="semibold">{title}</Text>
                    </span>
                )}

                {onClose && (
                    <button
                        className={cx('close')}
                        type="button"
                        onClick={onClose}
                    >
                        <CloseLinear />
                    </button>
                )}
            </div>

            <div className={cx('root', { hidden: !isOpen })}>
                {children}

                {actions && <div className={cx('actions')}>{actions}</div>}
            </div>
        </>
    );
};

ProfileModal.propTypes = {
    actions: node,
    actionsLayout: oneOf(['vertical', 'horizontal']),
    children: node,
    id: string,
    isOpen: bool,
    onClose: func,
    size: oneOf(['small', 'medium', 'large', 'x-large']),
    title: node,
};

ProfileModal.defaultProps = {
    actionsLayout: 'horizontal',
    size: 'medium',
};
