import React, { useMemo } from 'react';
import {
    bool,
    func,
    number,
    object,
    objectOf,
    oneOf,
    oneOfType,
    shape,
    string,
} from 'prop-types';

import { Modal as DSModal } from '@s7/ui-kit';

import MobileTitle from './MobileTitle';
import { ModalWrapper } from './ModalWrapper';

import { useWindowSize } from '../../hooks';

const Modal = React.memo(function Modal({
    centered,
    className,
    ds,
    dsModalProps = {},
    mobileTitleProps,
    onClose,
    widthAuto,
    withoutOverlay,
    widthSize,
    id,
    isOpen,
    reactModalStyle,
    ...props
}) {
    const { isMobile, width: screenWidth } = useWindowSize();

    const titleComponent = isMobile && mobileTitleProps && dsModalProps && (
        <MobileTitle title={dsModalProps.title} {...mobileTitleProps} />
    );
    const ModalType = ds ? DSModal : 'div';
    const dsProps = useMemo(
        () =>
            ds
                ? {
                      ...(isMobile
                          ? { width: screenWidth < 320 ? 320 : '100%' }
                          : {}),
                      titleComponent,
                      ...dsModalProps,
                  }
                : {},
        [ds, isMobile, dsModalProps, screenWidth, titleComponent],
    );

    return (
        <ModalWrapper
            {...{
                centered,
                className,
                ds,
                id,
                isOpen,
                onClose,
                style: reactModalStyle,
                widthAuto,
                widthSize,
                withoutOverlay,
            }}
        >
            <ModalType {...dsProps} {...props} />
        </ModalWrapper>
    );
});

Modal.propTypes = {
    centered: bool,
    className: string,
    ds: bool,
    dsModalProps: object,
    id: string,
    isOpen: bool,
    mobileTitleProps: object,
    onClose: func,
    reactModalStyle: shape({
        content: objectOf(oneOfType([number, string])),
        overlay: objectOf(oneOfType([number, string])),
    }),
    vAlign: string,
    widthAuto: bool,
    widthSize: oneOf(['x-medium']),
    withoutOverlay: bool,
};

export default Modal;
