import { createAction, createReducer } from 'redux-act';
import { isString } from 'utils/type';
import { v4 as uuidv4 } from 'uuid';

export const prefix = 'shared';

export const initialState = {
    achievement: null,
    isFooterChatWidgetOpen: false,
    isModalOpen: false,
    isPriorityCardOpen: false,
    /**
     * type Notification = {
     *   type: 'default' | 'success' | 'warning' | 'error'
     *   title?: string
     *   message: VNode | string
     *   delay?: number (ms)
     * }
     *
     * type Notifications = Notification[]
     */
    notifications: [],
};

export const changeModal = createAction(`${prefix}/changeModal`);
export const changePriorityCardOpen = createAction(
    `${prefix}/changePriorityCardOpen`,
);
export const setFooterChatWidgetOpen = createAction(
    `${prefix}/isFooterChatWidgetOpen`,
);

export const addNotification = createAction(`${prefix}/addNotification`);
export const removeNotification = createAction(`${prefix}/removeNotification`);

const handleChangeModal = (state, { isOpen }) => ({
    ...state,
    isModalOpen: isOpen,
});
const handleSetFooterChatWidgetOpen = (state, value) => ({
    ...state,
    isFooterChatWidgetOpen: value,
});
const handleChangePriorityCardOpen = (state, isOpen) => ({
    ...state,
    isPriorityCardOpen: isOpen,
});

const handleAddNotification = (
    state,
    { id = uuidv4(), type = 'default', title = '', delay = null, message = '' },
) => {
    const lastNotification =
        state.notifications[state.notifications.length - 1];
    const shouldAdd =
        !lastNotification ||
        (lastNotification &&
            ((isString(lastNotification.message) &&
                isString(message) &&
                lastNotification.message !== message) ||
                lastNotification.message?.props?.text !==
                    message?.props?.text));

    return shouldAdd
        ? {
              ...state,
              notifications: [
                  ...state.notifications,
                  { delay, id, message, title, type },
              ],
          }
        : state;
};

const handleRemoveNotification = (state, id) => ({
    ...state,
    notifications:
        state.notifications.filter(notification => notification.id !== id) ||
        [],
});

export default createReducer(
    {
        [addNotification]: handleAddNotification,
        [changeModal]: handleChangeModal,
        [changePriorityCardOpen]: handleChangePriorityCardOpen,
        [removeNotification]: handleRemoveNotification,
        [setFooterChatWidgetOpen]: handleSetFooterChatWidgetOpen,
    },
    initialState,
);

export * from './selectors';
