import React, { useCallback, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import {
    arrayOf,
    bool,
    element,
    func,
    oneOf,
    oneOfType,
    shape,
    string,
} from 'prop-types';

import cx from './Modal.sss';

import { UrlStateService } from '../../services';
import { changeModal } from '../../store/modules/shared';
import { getScrollbarWidth, isSSR, isUndefined } from '../../utils';

export const ModalWrapper = React.memo(function ModalWrapper({
    isOpen,
    className,
    onClose,
    overlayClassName,
    centered,
    widthAuto,
    children,
    classes,
    ds,
    widthSize,
    withoutOverlay,
    id,
    ...props
}) {
    const dispatch = useDispatch();

    const handleOpen = useCallback(
        body => {
            body.style.display = 'block';
            body.style.marginRight = `${getScrollbarWidth()}px`;

            dispatch(changeModal({ isOpen: true }));

            if (id) {
                UrlStateService.set(id, { onRemove: onClose });
            }
        },
        [dispatch, id, onClose],
    );

    const handleClose = useCallback(
        body => {
            body.style.removeProperty('margin-right');
            dispatch(changeModal({ isOpen: false }));

            if (id) {
                UrlStateService.remove(id);
            }
        },
        [dispatch, id],
    );

    useEffect(() => {
        if (isSSR) {
            return undefined;
        }

        const { body } = document;

        if (isUndefined(isOpen)) {
            handleOpen(body);
        } else if (isOpen) {
            handleOpen(body);
        } else {
            handleClose(body);
        }

        if (isUndefined(isOpen)) {
            return () => {
                handleClose(body);
            };
        }

        return () => {};
    }, [handleClose, handleOpen, isOpen]);

    return (
        <ReactModal
            className={cx(
                'container',
                {
                    'container_for-ds': ds,
                    widthAuto,
                    [`widthSize_${widthSize}`]: widthSize,
                },
                className,
            )}
            ariaHideApp={false}
            onRequestClose={onClose}
            {...(isUndefined(isOpen) ? { isOpen: true } : { isOpen })}
            {...(!withoutOverlay
                ? {
                      overlayClassName: cx(
                          'overlay',
                          {
                              centered,
                          },
                          overlayClassName,
                      ),
                  }
                : {})}
            {...props}
        >
            {children}
        </ReactModal>
    );
});

const classesShape = shape({
    container: string,
    overlay: string,
});

ModalWrapper.propTypes = {
    centered: bool,
    children: oneOfType([element, arrayOf(element)]),
    classes: classesShape,
    className: string,
    ds: bool,
    id: string,
    isOpen: bool,
    onClose: func,
    overlayClassName: string,
    vAlign: string,
    widthAuto: bool,
    widthSize: oneOf(['x-medium']),
    withoutOverlay: bool,
};
