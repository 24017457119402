let scrollTop = 0;

const revertScroll = (setScroll = true, { maxWidth } = {}) => {
    const { body } = document;

    body.style.position = null;
    body.style.height = null;
    body.style.width = null;
    body.style.overflow = null;

    if (setScroll && window.innerWidth <= maxWidth && scrollTop !== 0) {
        window.scrollTo(0, scrollTop);
    }

    scrollTop = 0;
};

const disableScroll = (setScroll = true, { maxWidth } = {}) => {
    if (window.innerWidth <= maxWidth) {
        const { body } = document;

        if (setScroll) {
            const doc = document.documentElement;

            scrollTop =
                (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        }

        body.style.position = 'fixed';
        body.style.height = '100%';
        body.style.width = '100%';
        body.style.overflow = 'hidden';
    }
};

const disableScrollGetter = ({ maxWidth = 767 } = {}) => {
    const resize = () => {
        if (window.innerWidth > maxWidth) {
            revertScroll(false);
        } else {
            disableScroll(false);
        }
    };

    const clear = () => {
        revertScroll(true, { maxWidth });

        if (window.removeEventListener) {
            window.removeEventListener('resize', resize);
        }
    };

    return (disable = true) => {
        if (disable) {
            if (window.innerWidth > maxWidth) {
                clear();
            } else {
                if (window.addEventListener) {
                    window.addEventListener('resize', resize);
                }

                disableScroll(true, { maxWidth });
            }
        } else {
            clear();
        }
    };
};

export default disableScrollGetter;
