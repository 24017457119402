import React from 'react';
import { func, node } from 'prop-types';

import { Title } from '@s7/ui-kit';
import { ChevronNormalLeft } from '@s7/ui-kit/icons';

import cx from './Modal.sss';

const MobileTitle = ({ backIcon, onBack, rightSide, title }) => (
    <div className={cx('title')}>
        <div className={cx('title-left-side')}>
            <button
                className={cx('title-button')}
                type="button"
                onClick={onBack}
            >
                <span className={cx('title-icon')}>
                    {backIcon || <ChevronNormalLeft />}
                </span>
                <Title as="span" level={5}>
                    {title}
                </Title>{' '}
            </button>
        </div>
        {rightSide && <div className={cx('title-right-side')}>{rightSide}</div>}
    </div>
);

MobileTitle.propTypes = {
    backIcon: node,
    onBack: func,
    rightSide: node,
    title: node,
};

export default MobileTitle;
